import React, { Component } from 'react';
import './Home.css';
import Lottie from 'react-lottie';
import * as animationData from '../pug.json'
/**
 * Class to handle the rendering of the Home page.
 * @extends React.Component
 */
export default class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      play: false,
      pause: true,
    };
    this.url = './dog.mp3';
    this.audio = new Audio(this.url);
  }




  render() {
const defaultOptions = {
      loop: true,
      autoplay: true, 
      animationData: animationData.default,
      rendererSettings: {
        preserveAspectRatio: 'xMidYMid slice'
      }
    };
    return (
      <div className="About container">
        <audio id="audio"><source src="./dog.mp3" type="audio/mp3"></source></audio>
         <Lottie options={defaultOptions}
              height={400}
              width={400}
              isStopped={this.state.isStopped}
              isPaused={this.state.isPaused}/>          
      </div>
    );
  }
}
